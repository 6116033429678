.statement {
  &-section {
    margin-bottom: 20px;
  }

  &-title {
    width: 100%;
    font-size: 16px;
    border-bottom: 1px solid color('gray', 'lighten-2');
    margin: 0;
    padding: 10px;
  }
  &-row {
    width: 100%;
    padding: 10px;
    border-bottom: 1px solid color('gray', 'lighten-2');
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  &-total {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &-total-label {
    padding: 10px;
  }
  &-total-value {
    padding: 10px;
    border-right: 1px solid color('gray', 'lighten-2');
    border-left: 1px solid color('gray', 'lighten-2');
    border-bottom: 1px solid color('gray', 'lighten-2');
  }

  &-amount {
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }

  &-amount-label {
    padding: 10px;
    border: 1px solid color('gray', 'lighten-2');
    border-right: none;
  }

  &-amount-value {
    padding: 10px;
    border: 1px solid color('gray', 'lighten-2')
  }


}