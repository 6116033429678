.timepicker {

  &-wrapper {
    background: color('basic', 'white');
    padding: 10px;
    border: 1px solid color('gray', 'lighten-2');
    border-top: none;
  }

  &-label {
    font-size: 14px;
    text-align: center;
    padding-bottom: 10px;
  }

  &-component {
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100%;

    .ember-power-select-trigger {
      min-width: 100px;
    }
  }
}