.table {
  width: 100%;
  max-width: 100%;
  margin-bottom: 10px;
  background-color: transparent;

  a {
    color: color('brand', 'secondary');
    font-weight: 600;
    transition: $transition-fast;
    &:hover {
      color: darken(color('brand', 'secondary'), 30);
      text-decoration: none;
    }
  }

  th,
  td {
    padding: 0;
    font-size: 14px;
    height: 40px;
    vertical-align: center;
    border-top: 1px solid color('gray', 'lighten-1');
  }

  thead th {
    vertical-align: bottom;
    border-bottom: 1px solid color('gray', 'lighten-1');
    border-top: none;
  }

  tbody + tbody {
    border-top: 1px solid color('gray', 'lighten-1');
  }

  .table {
    background-color: lightgray;
  }
  &-footer {
    font-size: 12px;
  }

  .btn {
    padding: 0;
    min-width: inherit;
  }

  &-week-buttons {
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin-top: 15px;
  }
}

.table-sm {
  th,
  td {
    padding: 7px;
  }
}

.table-bordered {
  border: 1px solid transparent;

  th,
  td {
    border-bottom: 1px solid color('gray', 'lighten-1');
  }

  thead {
    th,
    td {
      border-bottom-width: 2px;
      font-size: $fontSize-normal;
      font-weight: 600;
      padding: 10px 0;
    }
  }
}

.table-borderless {
  th,
  td,
  thead th,
  tbody + tbody {
    border: 0;
  }
}

.table-responsive {
  @each $breakpoint in map-keys($grid-breakpoints) {
    $next: breakpoint-next($breakpoint, $grid-breakpoints);
    $infix: breakpoint-infix($next, $grid-breakpoints);

    &#{$infix} {
      @include media-breakpoint-down($breakpoint) {
        display: block;
        width: 100%;
        overflow-x: auto;
        -webkit-overflow-scrolling: touch;
        -ms-overflow-style: -ms-autohiding-scrollbar;

        > .table-bordered {
          border: 0;
        }
      }
    }
  }
}

.globalSearch {
  margin-bottom: 15px;
  label {
    font-size: $fontSize-mili;
    margin-right: 5px;
  }
  input {
    border: none;
    border-bottom: 1px solid color('gray', 'base');
    outline: none;
    font-size: $fontSize-mili;
  }
}

.truck-board-wrapper {
  .filter-bar {
    margin-top: 10px;
  }
}

.truck-board-table {
  td,
  th {
    border: 1px solid black;
    font-size: 12px;
    font-weight: bold;
    padding: 9px;
    text-align: center;
  }

  .clickable {
    cursor: pointer;
  }
}

.truck-board-hos-row {
  padding: 0;

  td {
    border: none;

    &:last-of-type {
      border-right: none;
    }
  }

  &:last-of-type {
    border-bottom: none;

    td {
      border-bottom: none;
    }
  }

  .colored-value {
    color: red;
  }
}

.table-drug-test {
  &--Negative {
    color: color('basic', 'green');
  }
  &--Positive {
    color: color('basic', 'red');
  }
}

.truck-board-multiselect {
  display: flex;
  justify-content: left;
  align-items: center;
  height: 50px;
  padding: 0;
  margin: 15px 0 0 0;

  .validation-field-label {
    width: auto;
    margin-right: 20px;
  }
}

.m-table {

  &.-truckBoard {

    .m-table__header {
      display: flex;
      margin-top: 20px;
      background-color: color('basic', 'white');
      padding: 16px 0 0;
      position: fixed;
      width: 100%;
      left: 260px;
      z-index: 2;
      max-width: calc(100% - 300px);

      &::before {
        content: '';
        position: absolute;
        left: -500px;
        right: -500px;
        top: -300px;
        height: calc(100% + 300px);
        background-color: color('basic', 'white');
        box-shadow: 0 0 12px -2px rgba(0, 0, 0, 0.2);
      }

      & > * {
        position: relative;
      }

      span {
        font-weight: 600;
        color: color('gray', 'darken-5');
        font-size: $fontSize-normal;
      }

      &--heading {
        flex: 0 0 calc((100% - 548px) / 6);
        max-width: calc((100% - 548px) / 6);
        font-size: $fontSize-normal;
        padding: 0 10px 10px;

        &.-extended {
          flex: 0 0 480px;
          max-width: 480px;
          position: relative;
          padding: 10px;
          border: 1px solid color('gray', 'lighten-3');
          border-bottom: 0;
          border-radius: 3px;
          margin-top: -11px;

          .label-group {
            position: absolute;
            top: -20px;
            left: 50%;
            transform: translateX(-50%);
            font-size: 12px;
            font-weight: 600;
            color: color('gray', 'darken-5');
          }
        }

        p {
          width: 100%;
          margin-bottom: 0;
        }

        .status {
          font-size: 11px;
          color: color('gray', 'base');
        }
      }

      &--heading.status {
        margin: 0 auto;

        span {
          padding-left: 10px;
        }

        .status {
          padding-left: 0;
        }
      }
    }
  }

  &__load {

    &--header,
    &--content {
      display: flex;
      width: 100%;

      & > span,
      & > div {
        flex: 0 0 14.28%;
        max-width: 14.28%;
        padding: 0 5px;
        font-size: $fontSize-normal;
      }

      &.-info {

        & > span,
        & > div {
          flex: 0 0 16.67%;
          max-width: 16.67%;
        }
      }

    }

    &--header {

      span {
        font-weight: 600;
      }
    }

    &--content {
      margin-top: 20px;
      position: relative;

      .btn-selectAdd {
        opacity: 0;
        transition: .2s;
        padding: 0;
        position: absolute;
        top: 0;
        right: 0;
        background: color('gray', 'lighten-2');
        border: none;

        &:hover {
          background: color('gray', 'lighten-2');
        }

        i {
          &::before {
            color: color('basic', 'black');
            font-size: 10px;
            position: absolute;
            top: 50%;
            left: 8px;
            transform: translateY(-50%);
          }
        }
      }

      & > div {
        position: relative;

        &:hover {

          .btn-selectAdd {
            opacity: 1;
          }
        }
      }

      .table-body-events {
        position: absolute;
        top: -5px;
        left: calc(50% - 13px);
      }

      &.-days {
        background-color: color('basic', 'white');
        position: relative;

        &::before {
          content: '';
          width: 100%;
          height: calc(100vh - 400px);
          position: absolute;
          top: -20px;
          left: 0;
          background-color: color('basic', 'white');

        }

        & > div:not(:last-of-type) {
          position: relative;

          &::before {
            content: '';
            width: 1px;
            height: calc(100vh - 400px);
            position: absolute;
            right: 0;
            top: -20px;
            background-color: rgba(0, 0, 0, .1);
          }
        }
      }

      div[class*=status-] {
        padding: 20px 5px 5px;
        border-radius: 3px;
      }

    }

  }

  &__group {
    display: flex;
    justify-content: space-between;

  }

  &__row {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    background-color: color('basic', 'white');
    border-radius: 3px;
    margin-top: 20px;
    padding: 16px 10px;

    & > div:not(.m-truckRow__extended):not(.row-group):not(.-info) {
      flex: 0 0 calc((100% - 524px) / 6);
      max-width: calc((100% - 524px) / 6);
      padding: 0 10px;

      &.bigger {
        min-width: 240px;
        max-width: 240px;
      }

    }

    .row-group {
      flex: 0 0 480px;
      max-width: 480px;
      display: flex;
      justify-content: space-between;

      div:not(.bigger) {
        width: calc((100% - 240px) / 2);
        padding: 0 10px;
        display: flex;
        align-items: center;
      }

      .bigger {
        width: 305px;
        padding: 0 18px;
      }
    }

  }

  &__body {
    font-size: 14px;
    position: relative;
    padding-top: 80px;
  }

  &__content {
    display: flex;
    flex-wrap: wrap;
    padding: 0 10px;

    & > * {
      width: 100%;
    }

    .extend-title {
      display: block;
      color: color('gray', 'darken-5');
      font-weight: 600;

      &:not(:first-child) {
        margin-top: 10px;
      }
    }

    &--wrapper {

      p {
        display: block;
        width: 100%;
      }
    }

    &.-note {

      .extend-title {
        display: flex;
        margin-bottom: 10px;
      }

      .btn-icon {
        padding: 0;
      }
    }

    &.-location {

      .m-table__content--wrapper {
        display: flex;
        flex-wrap: wrap;

        & > * {
          width: 100%;
        }
      }
    }

    &.-location,
    &.-note {
      width: 355px;
    }

    &.-lastStop {
      width: 375px;
    }

    &.truck-board-hos {
      width: 178px;
    }

    &.-booked {
      width: 152px;
    }

  }

  &__header--top {
    position: fixed;
    z-index: 5;
    top: 80px;

    & > * {
      position: relative;
      z-index: 2;
    }

    & + div button {
      z-index: 2;
    }

    &Inner {

      & > div {
        display: flex;
        flex-wrap: wrap;
      }

      .validation-field-label {
        width: 100%;
        font-weight: 600;
        font-size: $fontSize-normal;
        margin-bottom: 10px;
        color: color('gray', 'darken-5');
      }
    }
  }

  .more-info {
    display: flex;

    .btn {
      position: relative;

      &::before {
        transition: $transition-medium;
        content: '';
        width: 10px;
        height: 10px;
        position: absolute;
        top: 0;
        left: 0;
        border-left: 2px solid color('brand', 'primary');
        border-bottom: 2px solid color('brand', 'primary');
        transform: rotate(-45deg);
      }
    }
  }

  .more-info.opened {

    .btn::before {
      transform: rotate(-225deg);
    }

  }

  .ember-power-select-trigger {
    min-width: 138px;
    padding-right: 20px;
  }

  .truck-board-status-field {
    padding: 8px 40px;
    max-width: 70px;
    border-radius: 5px;

    &-wrapper {
      display: flex;
      justify-content: center;

      & + div {
        justify-self: flex-end;
        margin-left: auto;

      }
    }
  }

  .status {

    &.-color {
      font-weight: 600;
    }
  }

  .table-header-custom,
  .table-body {
    border: none;
    background-color: transparent;
  }

  .table-header-date {
    font-size: 10px;
    color: rgba(0, 0, 0, .5);
  }

  .truck-board-hos {

    .truck-board-hos-row {
      display: flex;
      justify-content: space-between;
      max-width: 120px;
    }
  }
}

.table-header-name, .table-header-date {
  text-align: center;
}

//additional spacing for different trucks on board
.row.m-table__row--wrapper.first-different {
  margin-top: 20px;

  &:first-of-type {
    margin-top: 0;
  }
}

.table-body-info {
  position: relative;
}
