.validation-field {
  &-wrapper {
    margin-bottom: 20px;
    position: relative;
  }
  &-label {
    font-size: 12px;
    color: color('gray', 'darken-3');
    margin-bottom: 5px;
  }
  &-input {
    position: relative;
    border: 1px solid color('gray', 'lighten-2');
    padding: 10px;
    font-size: 12px;
    line-height: 12px;
    color: $color-input-text;
    width: 100%;
    min-width: 200px;
    //max-width: 220px;
    box-shadow: $shadow-light;
    transition: $transition-fast;
    background: color('basic', 'white');
    &:hover {
      //box-shadow: $shadow-basic;
    }
    &:focus {
      border: 1px solid #bdc5c9;
      outline: none;
    }
  }

  &-symbol {
    position: absolute;
    top: 31px;
    left: 8px;
    z-index: $zIndex-3;
    color: color('gray', 'darken-3');
    font-size: 12px;
  }

  &-input-wrapperCalendar {
    position: relative;
    //max-width: 225px;
    &:after {
      content: '\e800';
      font-family: "fontello";
      position: absolute;
      top: 50%;
      right: 15px;
      font-size: 15px;
      color: color('brand', 'primary');
      transform: translateY(-50%);
    }
  }

  //TODO Add icon for time
  &-input-wrapperTimePicker {
    position: relative;
    //max-width: 225px;
    &:after {
      content: '\e800';
      font-family: "fontello";
      position: absolute;
      top: 9px;
      right: 15px;
      font-size: 15px;
      color: color('brand', 'primary');
    }
  }

  &-error-list {
    position: relative;
    width: 100%;
  }
  &-error-item {
    position: absolute;
    top: -15px;
    left: 0;
    color: color('basic', 'red');
    font-size: 10px;
    display: none;
    &:first-of-type {
      display: block;
    }
  }
  &-error-itemDoc {
    left: 50%;
    transform: translateX(-50%);
  }
  &-inputDate {
    cursor: pointer;
  }
  &-success {
    position: absolute;
    width: 100%;
    text-align: center;
    margin-top: 0;
    font-size: 12px;
    color: color('brand', 'primary');
  }
  &-text-counter {
    position: absolute;
    bottom: -17px;
    right: 0px;
    font-size: 12px;
    color: color('gray', 'darken-3');
  }
}

.validation-field-with-symbol {
  input {
    padding-left: 30px;
  }
}

.validation-field-wrapper {
  textarea {
    height: 120px !important;
    max-height: 200px;
    min-height: 34px;
  }
}

.validation-field-with-add {
  .validation-field-component {
    width: calc(100% - 45px);
  }

  .btn-selectAdd-position {
    position: absolute;
    right: 15px;
    top: 23px;
    left: inherit;
  }
}

.large-select.validation-field-with-add {
  .btn-selectAdd-position {
    position: absolute;
    right: 20px;
    top: 32px;
    left: inherit;
  }
}

.required-field-wrapper {
  .validation-field-error-item {
    top: 5px;
  }
}

.owner-trucks {
  &:last-child {
    span {
      display: none;
    }
  }
}

.validation-field-wrapper {
  .ember-power-select-clear-btn {
    color: color('brand', 'primary');
    right: 35px;
    top: 3px;
    font-size: 20px;
  }
}
