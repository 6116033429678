.main-title {
    width: 50%;

  &-wrapper {
    position: fixed;
    top: 0;
    left: 200px;
    width: 100%;
    color: $main-title-color;
    letter-spacing: 1px;
    font-size: 24px;
    padding: 24px 10px 24px 20px;
    background: $main-title-bg;
    font-weight: bold;
    z-index: $zIndex-5;
    height: 70px;
    border-bottom: 1px solid color('gray', 'lighten-2');
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &-buttons {
    width: 50%;
  }
}
