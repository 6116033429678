.datepicker {
  border: 1px solid color('gray', 'lighten-2');
  border-top: none;
  padding: 8px;
  animation: showdatepicker .3s ease;
  animation-fill-mode: forwards;
  transform: translateY(-50px) scaleY(0.7);
  opacity: 0;
  &-dropdown {
    width: 100%;
    min-width: 200px;
    max-width: 220px;
    background: color('basic', 'white');
  }
  .ember-power {
    &-calendar-weekdays {
      padding: 10px 0 5px 0;
    }
    &-calendar-nav-control {
      font-size: 120%;
      color: color('brand', 'primary')
    }
    &-calendar-day {
      border-bottom: 1px solid transparent;
      cursor: pointer;
      transition: $transition-medium;
      &:hover {
        color: color('brand', 'primary');
        border-bottom: 1px solid color('brand', 'primary');
      }
    }
    &-calendar-day--selected {
      border: 1px solid color('brand', 'primary');
      color: color('brand', 'primary');
      font-weight: 600;
    }
    &-calendar-row {
      padding: 4px 0;
    }
    &-select-trigger {
      min-width: 46%;
      max-width: 46%;
      margin: 0 2%;
      border: none;
      line-height: 20px;
      cursor: pointer;
      height: 30px;
      box-shadow: none;
    }
    &-select-selected-item {
      position: absolute;
      left: 0;
    }
    &-select-status-icon {
      &:after {
        top: -12px;
        right: 0;
        font-size: 10px;
      }
    }
  }
  &-input {
    @extend .validation-field-input;
  }
}

@keyframes showdatepicker {
  from {
    transform: translateY(-50px) scaleY(0.7);
    opacity: 0;
  }
  to {
    transform: translateY(0) scaleY(1);
    opacity: 1;
  }
}