/* Line height */
$lineHeight-tightest: 10px;
$lineHeight-tighter: 12px;
$lineHeight-tight: 14px;
$lineHeight-baseSans: 15px;
$lineHeight-base: 17px;
$lineHeight-loose: 20px;
$lineHeight-looser: 22px;

/* Font sizes */
$fontSize-pico: 6px;
$fontSize-nano: 8px;
$fontSize-micro: 10px;
$fontSize-mili: 12px;
$fontSize-normal: 14px;
$fontSize-kilo: 16px;
$fontSize-mega: 18px;
$fontSize-giga: 20px;
$fontSize-tera: 26px;

/* Font weight
 N = normal     3 = light
 I = italic     4 = normal
                7 = bold
*/

.font-main {
  &-N1,
  &-N4,
  &-N7,
  &-I4,
  &-I7 {
    font-family: 'Poppins', sans-serif;
  }
}

.font {
  &-main {
    &-N3 {
      font-weight: 300;
      font-style: normal;
    }
    &-N4 {
      font-weight: 400;
      font-style: normal;
    }
    &-N7 {
      font-weight: 700;
      font-style: normal;
    }
    &-I1 {
      font-weight: 100;
      font-style: italic;
    }
    &-I4 {
      font-weight: 400;
      font-style: italic;
    }
    &-I7 {
      font-weight: 700;
      font-style: italic;
    }
  }
}


