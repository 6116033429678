/* form */
$color-input-border: color('gray', 'lighten-3');
$color-input-borderFocus: color('brand', 'secondary');
$color-input-text: color('gray', 'darken-2');

/* side-bar */
$side-bar-color: #899dc1;
$side-bar-bg: color('brand', 'secondary');

/* main-page */
$main-page-color: color('gray', 'darken-4');
$main-page-bg: color('gray', 'lighten');

/* main title */
$main-title-color: color('brand', 'secondary');
$main-title-bg: color('basic', 'white');

/* boxes */
$box-bg: color('basic', 'white');
$box-color: color('gray', 'darken-4');
$box-border-color: color('gray', 'lighten-4');
$box-border-width: $border-thin;

/* transitions */
$transition-fast: all .3s ease;
$transition-medium: all .6s ease;
$transition-slow: all .9s ease;