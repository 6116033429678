.loads-board {
    position: relative;

    .main-box-wrapper {
        background: transparent;
        border: none;
    }
    
    .load-statuses {
        display: flex;
        flex-wrap: wrap;
        
        label {
            flex: 0 0 33%;
            max-width: 33%;
        }
    }

    .table-header-custom {
        margin-top: 20px;
    }

    .table-week-buttons {
        flex: 0 0 100%;
        max-width: 100%;
        margin-left: auto;
        justify-content: center;
        position: relative;

        .table-current-btn {
            position: relative;
        }

        button:not(.btn-primary) {
            background: transparent;
            padding: 0;
        }

        .btn-primary {
            background: transparent;
            border: 1px solid color('gray','lighten-1');
            color: color('gray','darken-2');

            &:hover {
                background:  color('brand','primary');
                color: color('basic', 'white');
                border-color: color('brand','primary');
            }
        }

        .prev,
        .next {
            position: absolute;
            top: 50%;

            button {
                content: '';
                width: 8px;
                height: 8px;
                border: 0;
                border-left: 2px solid color('gray', 'base');
                border-bottom: 2px solid color('gray', 'base');
                padding: 10px;
                min-width: auto;
            }

        }

        .prev {
            left: -20px;
            transform: translateY(-50%) rotate(45deg);

        }

        .next {
            right: -20px;
            transform: translateY(-50%) rotate(-135deg);

        }
    }

    .table-scroll {
        margin-top: 115px;
    }

    .fixed {
        position: fixed;
        top: 110px;
        z-index: 5;
        width: calc(100% - 280px);
        background: color('basic', 'white');

        &::before {
            content: '';
            left: -500px;
            right: -500px;
            top: -70px;
            position: absolute;
            height: calc(100% + 100px);
            background-color: color('basic', 'white');
            box-shadow: 0 0 12px -2px rgba(0, 0, 0, .2);
            z-index: 0;
        }
    }

    .main-box-title {
        position: fixed;
    }

    .checkIn {
        position: absolute;
        top: 8px;
        left: 79px;

        &::before {
            content: '';
            position: absolute;
            width: 10px;
            height: 7px;
            border: 0;
            border-left: 2px solid color('gray', 'darken-5');
            border-bottom: 2px solid color('gray', 'darken-5');
            transform: translateY(-50%) rotate(315deg);

        }
    }

    .checkOut {
        position: absolute;
        top: 8px;
        left: 80px;

        &::after {
            content: '';
            position: absolute;
            left: 5px;
            width: 10px;
            height: 7px;
            border: 0;
            border-left: 2px solid color('gray', 'darken-5');
            border-bottom: 2px solid color('gray', 'darken-5');
            transform: translateY(-50%) rotate(315deg);
        }
    }

    .modal-content {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        margin-top: 20px;

        & > span {
            width: 100%;

            &:not(:first-child) {
                margin-top: 5px;
            }
        }

        &.empty {
            margin-top: 0;
        }
    }

    .events {
        display: block;
        margin-top: 30px;
    }

    .m-table__row {
        margin-top: 10px;
    }

}