.driver {
  &-info-modal {
    @include column-start--spaceBetween;
  }
  &-info-wrapper {
    @include row-center--start;
    color: color('brand', 'secondary');
    margin-bottom: 10px;
  }
  &-info-label {
    width: 105px;
    color: color('gray', 'darken-1');
    font-size: 12px;
  }
  &-hire-part {
    position: relative;
    display: block;
    width: 100%;
    padding: 20px 0;
    margin-top: 20px;
    border-top: 1px solid color('gray', 'lighten-2');
  }

  &-hire-btns {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    .btn {
      margin-right: 15px;
    }
  }

  &-missing-documents {
    &-wrapper {
      display: flex;
      width: 100%;
      flex-direction: column;
      margin: 10px 0;
    }
    &-title {
      width: 100%;
      color: color('gray', 'darken-1');
      font-size: 14px;
    }
    &-items {
      display: flex;
      width: 100%;
      margin-top: 10px;
    }
    &-item {
      display: flex;
      width: auto;
      color: color('basic', 'red');
      font-size: 14px;
      margin-right: 10px;
    }
  }

  &-document-title {
    position: relative;
    float: left;
    width: 100%;
    color: color('brand', 'secondary');
    margin: 20px 0 10px 0;
  }
  &-timeline {
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    height: 300px;
    overflow-x: auto;
    padding: 0 50px;
    &:after {
      content: '';
      position: absolute;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
      width: 100%;
      height: 2px;
      background: color('gray', 'base');
      z-index: 100;
    }
  }
  &-timeline-event {
    position: relative;
    width: 150px;
    margin-right: 105px;
    text-align: center;
    &:after {
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 20px;
      height: 20px;
      border: 1px solid color('brand', 'secondary');
      border-radius: 50%;
      background: color('brand', 'secondary');
      z-index: 200;
    }
    &:before {
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 35px;
      height: 35px;
      border: 1px solid color('brand', 'secondary');
      border-radius: 50%;
      background: transparent;
      z-index: 200;
    }
  }
  &-timeline-part-border {
    position: relative;
    width: 150px;
    margin-right: 10px;
    text-align: center;
    &:after {
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 50px;
      height: 50px;
      border-top: 1px solid color('brand', 'secondary');
      border-radius: 50%;
      background: transparent;
      z-index: 200;
    }
  }
  &-timeline-linePointer {
    position: absolute;
    width: 2px;
    top: 17px;
    background: color('brand', 'primary');
    left: 50%;
    transform: translateX(-50%) scaleY(0);
    height: 0;
    animation: bla 4s ease;
    animation-fill-mode: forwards;
    transform-origin:  top;
    transition: all 3s ease;
    &:after {
      content: '';
      position: absolute;
      top: 65px;
      left: 50%;
      transform: translateX(-50%);
      width: 10px;
      height: 10px;
      border-radius: 50%;
      background: color('brand', 'primary');
    }
  }
  &-timeline-eventPointer {
    position: absolute;
    width: 2px;
    bottom: 25px;
    background: color('brand', 'secondary');
    left: 50%;
    transform: translateX(-50%);
    height: 30px;
    transition: all 3s ease;
    &:after {
      content: '';
      position: absolute;
      width: 35px;
      bottom: -25px;
      background: transparent;
      left: 50%;
      border-top: 1px solid color('brand', 'secondary');
      transform: translateX(-50%);
      height: 25px;
      border-radius: 50%;
    }
  }
  &-timeline-name {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: 60px;
    width: 150px;
    text-align: center;
    font-size: 14px;
    font-weight: 600;
    &:after {
      content: '';
      position: absolute;
      top: -7px;
      left: 50%;
      width: 40px;
      height: 2px;
      transform: translate(-50%);
      background-color: color('brand', 'secondary');
    }
  }
  &-timeline-date {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    top: 100px;
    font-size: 11px;
    text-align: center;
    width: 150px;
  }
}

.one {
  .driver-timeline-name {
    &:after {
      background-color: rebeccapurple;
    }
  }
  .driver-timeline-linePointer {
    background: rebeccapurple;
    &:after {
      background: rebeccapurple;
    }
  }
  .driver-timeline-eventPointer {
    background: rebeccapurple;
    &:after {
      border-top: 1px solid rebeccapurple;
    }
  }
}
.two {
  .driver-timeline-name {
    &:after {
      background-color: dodgerblue;
    }
  }
  .driver-timeline-linePointer {
    background: dodgerblue;
    &:after {
      background: dodgerblue;
    }
  }
  .driver-timeline-eventPointer {
    background: dodgerblue;
    &:after {
      border-top: 1px solid dodgerblue;
    }
  }
}
.three {
  .driver-timeline-name {
    &:after {
      background-color: plum;
    }
  }
  .driver-timeline-linePointer {
    background: plum;
    &:after {
      background: plum;
    }
  }
  .driver-timeline-eventPointer {
    background: plum;
    &:after {
      border-top: 1px solid plum;
    }
  }
}
.four {
  .driver-timeline-name {
    &:after {
      background-color: greenyellow;
    }
  }
  .driver-timeline-linePointer {
    background: greenyellow;
    &:after {
      background: greenyellow;
    }
  }
  .driver-timeline-eventPointer {
    background: greenyellow;
    &:after {
      border-top: 1px solid greenyellow;
    }
  }
}

@keyframes bla {
  from {
    transform: scaleY(0);
    height: 0;
  }
  to {
    transform: scaleY(1);
    height: 65px;
  }
}
