.user-avatar {
  &-component {
    min-width: 150px;
    min-height: 150px;
    border-radius: 50%;
    width: 150px;
    height: 150px;
    background: color('basic', 'white');
    border: 1px solid color('brand', 'primary');
    position: relative;
    transition: $transition-fast;
    &:hover {
      .btn-avatar {
        bottom: 10px;
        right: 4px;
      }
    }

    img {
      display: flex;
      border-radius: 50%;
      width: 100%;
      height: 100%;
      object-fit: fill;
    }
  }

  &-tmp-wrapper {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;


    img {
      border-radius: 50%;
      width: auto;
      height: 150px;
    }
  }

  &-note {
    width: 100%;
    text-align: center;
    color: color('gray', 'darken-3');
  }
}
