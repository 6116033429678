//.load {
//  &-dashboard {
//    display: flex;
//    width: 100%;
//    flex-direction: column;
//    margin-top: 40px;
//  }
//
//  &-dashboard-header {
//
//  }
//  &-row {
//    width: 100%;
//    position: relative;
//    float: left;
//    border-bottom: 1px solid black;
//  }
//  &-number {
//    width: 55px;
//    border-right: 1px solid black;
//    position: relative;
//    float: left;
//    display: flex;
//    justify-content: center;
//    align-items: center;
//    min-height: 55px;
//    text-align: center;
//  }
//  &-day {
//    border-right: 1px solid black;
//    padding: 10px;
//    cursor: pointer;
//    min-width: calc(14.25% - 13px);
//    min-height: 55px;
//    position: relative;
//    float: left;
//    box-sizing: border-box;
//    width: calc(14.25% - 13px);
//    display: flex;
//    justify-content: center;
//    align-items: center;
//    flex-direction: column;
//  }
//
//  &-day-name {
//
//  }
//  &-day-date {
//
//  }
//
//  &-delete {
//    position: relative;
//    float: left;
//    width: 35px;
//    display: flex;
//    justify-content: center;
//    align-items: center;
//
//    .btn {
//      padding: 0;
//      width: 35px;
//      height: 55px;
//    }
//  }
//
//  &-events {
//    display: flex;
//    flex-direction: column;
//  }
//
//  &-event {
//    display: flex;
//    flex-direction: row;
//  }
//
//}
//
//.stop {
//  &-list {
//    margin-top: 10px;
//  }
//
//  &-list-item {
//    padding: 10px;
//    border: 1px solid black;
//    margin-bottom: 10px;
//  }
//}
//
//.load-dashboard-header {
//  .load-day {
//    cursor: default;
//  }
//}

.table {
  &-check-btn {
    .btn {
      top: 0;
    }
  }
  &-current-btn {
    .btn {
      background: darken(color('brand', 'primary'), 15);
      border: 1px solid darken(color('brand', 'primary'), 15);
    }
  }
  &-scroll {
    height: calc(100vh - 280px)
  }
  &-wrapper {
    position: relative;
    margin-top: 10px;

    display: flex;
    flex-flow: column;
    height: 100%;
    width: 100%;
  }
  &-header-custom {
    border: 1px solid color('gray', 'base');
    border-bottom-width: 2px;

    th {
      border-right: 1px solid color('gray', 'base');
      background: color('gray', 'lighten-4');
      font-weight: normal;
      padding: 5px 15px;
      font-size: 14px;
    }
  }
  &-header-date {
    font-size: 12px;
  }
  &-body {
    border: 1px solid color('gray', 'base');

    tr {
      border-bottom: 1px solid color('gray', 'base');
    }

    td {
      position: relative;
      border-right: 1px solid color('gray', 'base');
      background: color('basic', 'white');
      font-weight: normal;
      padding: 5px 10px;
      transition: $transition-fast;
      .btn-selectAdd {
        position: absolute;
        top: 3px;
        right: -25px;
        width: 20px;
        min-width: 20px;
        height: 20px;
        padding: 0;
        font-size: 9px;
      }
      &:hover .btn-selectAdd {
        right: 3px;
      }
    }
  }
  &-body-info-wrapper {
    @include column-start--spaceBetween;
  }
  &-body-info {
    @include column-start--spaceBetween;
    cursor: pointer;
  }
  &-body-type {
    position: relative;
    font-weight: bold;
    font-size: 12px;
    letter-spacing: 1px;
    margin: 3px 0 0 5px;
    &:before {
      content: '';
      position: absolute;
      top: 5px;
      left: -5px;
      color: color('gray', 'base');
      width: 3px;
      height: 3px;
      background: color('gray', 'darken-4');
      border-radius: 50%;
    }
    &:hover {
      cursor: pointer;
    }
  }
  &-body-time {
    font-size: 10px;
    font-style: italic;
    transition: $transition-fast;
    margin: 1px 0 0 5px;
    &:hover {
      cursor: pointer;
    }
  }
  &-body-location {
    font-size: 11px;
    transition: all 0.3s ease;
    margin: 5px;
    &:hover {
      cursor: pointer;
    }
  }
  &-body-events {
    font-size: 16px;
    color: color('brand', 'secondary');
    &:hover {
      cursor: pointer;
    }
  }
  &-body-link {
    a {
      @include row-center--start;
      color: color('brand', 'secondary');
      font-weight: bold;
      &:hover {
        text-decoration: none;
      }
    }
  }

  &-td-driver {
    cursor: pointer;
  }
}

.status-planned {
  background: color('brand', 'tertiary') !important;
}

.status-transit {
  background: color('basic', 'green') !important;
}

.status-completed {
  background: color('gray', 'lighten-1') !important;
}

.status-canceled {
  background: color('basic', 'red') !important;
}

.load {
  &-total-charges {
    margin: 20px 0;
  }
  &-driver-info {
    margin-bottom: 20px;
    span {
      padding: 0 15px;
      align-items: center;
      justify-content: center;
      display: flex;
    }
  }
  &-driver-sent-info {
    margin-left: 15px;
    background-color: color('gray', 'lighten-4');
    color: color('basic', 'red');
    border: 1px solid color('gray', 'lighten-1');
    padding: 10px;
  }
  &-statusCheck {
    display: flex;
    span {
      font-size: 13px;
      line-height: 25px;
      margin-right: 15px;
    }
    .validation-field-label {
      display: none;
    }
  }
  &-driver-email {
    margin-bottom: 20px;
    span {
      padding: 0 15px;
      align-items: center;
      justify-content: center;
      display: flex;
      font-size: 14px;
    }

    .btn-primary {
      background: white;
      border: 1px solid color('brand', 'primary');
      color: color('brand', 'primary');
      border-radius: 50%;
      height: 30px;
      width: 30px;
      padding: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      min-width: 30px;
      font-weight: bold;
    }
  }
}

.table-scroll {
  table {
    display: flex;
    flex-flow: column;
    height: 100%;
    width: 100%;
  }
  table thead {
    /* head takes the height it requires,
    and it's not scaled when table is resized */
    flex: 0 0 auto;
    width: 100%;
  }
  table tbody {
    /* body takes all the remaining available space */
    flex: 1 1 auto;
    display: block;
    overflow-y: auto;
  }
  table tbody tr {
    width: 100%;
  }
  table thead,
  table tbody tr {
    display: table;
    table-layout: fixed;
  }
}