// basic colors
$basic: (
        "red": #e51c23,
        "red-status": #bb3333,
        "green": #00a53e,
        "green-status": #26a556,
        "blue": #0079b3,
        "white": #fff,
        "black": #000,
        "green-light": #8aea92,
        "blue-light": #59a5d8,
        "yellow-light": #f7f06d,
        "yellow-status": #e8d127,
        "red-light": #e57070,
        "purple": #361C9E,
);

//brand colors
$brand: (
        "primary": #22BAA0,
        "secondary": #34425A,
        "tertiary": rgb(236, 199, 50),
);

//shades of gray colors
$gray: (
        "base": #9e9e9e,
        "lighten-5": #fafafa,
        "lighten-4": #f5f5f5,
        "lighten-3": #eeeeee,
        "lighten-2": #dce1e4,
        "lighten-1": #bdbdbd,
        "lighten": #f1f4f9,
        "darken-1": #74767d,
        "darken-2": #555,
        "darken-3": #4E5E6A,
        "darken-4": #212121,
        "darken-5" : #363a46
);

$colors: (
        "basic": $basic,
        "gray": $gray,
        "brand": $brand,
) !default;

// usage: color("name_of_color", "type_of_color")

@function color($color, $type) {
  @if map-has-key($colors, $color) {
    $curr-color: map-get($colors, $color);
    @if map-has-key($curr-color, $type) {
      @return map-get($curr-color, $type);
    }
  }
  @warn "Unknown `#{$color}` - `#{$type}` in $colors.";
  @return null;
}







