.load-progress-bar {
  padding: 100px 0 50px 0;
  width: 100%;
  display: flex;
  justify-content: space-around;
  background: color('basic','white');
  margin: 20px 0;

  .point {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    width: 100%;

    &:after {
      content: '';
      position: absolute;
      width: 100%;
      height: 3px;
      background-color: #D2D2D2;
      left: 55%;
      top: 15px;
    }

    &:last-of-type {
      &:after {
        content: '';
        position: absolute;
        width: 0;
        height: 0;
        background-color: transparent;
        left: 0;
        top: 0;
      }
    }
  }

  .circle {
    background: #D2D2D2;
    width: 31px;
    height: 31px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #D2D2D2;
    padding: 5px;
    border-radius: 50%;
    position: relative;
    z-index: 1;
  }

  .point.passed {

    .circle {
      background: color('brand', 'primary');
      color: color('basic','white');
      border: 1px solid color('brand', 'primary');
      padding: 5px 5px 5px 9px;
    }

    &::after {
      background: color('brand', 'primary');
    }
  }
  .point.current {

    .circle {
      background: color('basic','white');
      color: color('brand', 'primary');
      border: 2px solid color('brand', 'primary');
      box-sizing: border-box;
      padding: 5px 5px 5px 9px;
    }

  }

  .point.passed.current {
    &::after {
      background: #D2D2D2;
    }
  }

  .status-notification {
    position: absolute;
    top: -50px;
    background: color('basic', 'white');
    border-radius: 2px;
    border: 1px solid #EAEBF0;
    padding: 10px;
    font-size: 14px;
    width: 125px;
    -webkit-box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1), 0px 15px 25px rgba(0, 0, 0, 0.15);
    -moz-box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1), 0px 15px 25px rgba(0, 0, 0, 0.15);
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1), 0px 15px 25px rgba(0, 0, 0, 0.15);
    color: color('gray', 'darken-2');

    &:after {
      content: '';
      position: absolute;
      top: 36px;
      left: 55px;
      width: 0;
      height: 0;
      border-left: 5px solid transparent;
      border-right: 5px solid transparent;
      border-top: 5px solid color('basic', 'white');;
    }
  }

  .point-checkmark {
    position: relative;
    background: color('basic', 'white');
    width: 14px;
    height: 1.5px;
    transform: rotate(-45deg);
    border-radius: 2px;

    &:after {
      content: '';
      background: #fff;
      width: 6px;
      height: 1.5px;
      position: absolute;
      top: -2px;
      left: -2px;
      border-radius: 2px;
      transform: rotate(90deg);
    }
  }

  .point-down {
    position: absolute;
    background: color('brand', 'primary');
    top: 13px;
    left: 11.5px;
    width: 10px;
    height: 1.5px;
    transform: rotate(-45deg);
    border-radius: 2px;

    &:after {
      content: '';
      background: color('brand', 'primary');
      width: 10px;
      height: 1.5px;
      position: absolute;
      top: -4px;
      left: -5px;
      border-radius: 2px;
      transform: rotate(90deg);
    }
  }

  .point-number {
    color: color('gray', 'darken-2');
    font-size: 14px;
  }

  .status-label {
    font-size: 14px;
    margin-top: 5px;
    color: color('gray', 'darken-2')
  }
}