.main-box {
  &-wrapper {
    margin-bottom: 20px;
    min-height: 150px;
    display: flex;
    flex-direction: column;
    width: 100%;
    box-sizing: border-box;
    background: color('basic', 'white');
    border-radius: 0;
    border: 1px solid #eaebed;
    position: relative;
  }

  &-wrapper--disabled:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    overflow: hidden;
    background: rgba(33, 33, 33, 0.4);
    width: 100%;
    height: 100%;
    z-index: 400;
  }

  &-header {
    min-height: 40px;
    color: color('basic', 'white');
    font-size: 20px;
    font-weight: 500;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 30px 30px 0 30px;
  }

  &-title {
    font-weight: bold;
    color: color('brand', 'secondary');
  }

  &-content {
    position: relative;
    display: flex;
    height: auto;
    overflow-x: hidden;
    width: 100%;
    box-sizing: border-box;
    flex-direction: column;
    padding: 30px;

    .btn-add {
      position: absolute;
      top: 0;
      right: 30px;
    }

    .btn-check {
      position: absolute;
      top: 15px;
      right: 140px;
    }
  }

  &-footer {
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    .btn {
      margin-left: 10px;
    }
  }
}
