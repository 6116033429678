.login {
  &-page {
    height: auto;
    background: color('gray', 'lighten-4');
    @media (min-width: 768px) {
      height: 100vh;
    }
  }
  &-left-side {
    position: relative;
    background: url(/img/login-img3.jpg) bottom center no-repeat;
    width: 100%;
    height: 100%;
    background-size: cover;
    &:after {
      content: '';
      position: absolute;
      background: rgba(color('gray', 'base'), 0.4);
      top: 0;
      right: 0;
      width: 100%;
      height: 100%;
    }
  }
  &-form-wrapper {
    width: 100%;
    max-width: 320px;
    padding: 30px 10px 70px 10px;
    height: auto;
    border-radius: $borderRadius-small;
    background: color('basic', 'white');
    box-shadow: $shadow-basic;
    transition: $transition-fast;
  }
  &-form-logo {
    max-width: 100px;
    height: auto !important;
  }
  &-form-label {
    text-transform: uppercase;
  }
  &-form {
    position: relative;
    margin-top: 25px;
  }
  &-form-error {
    position: relative;
    font-size: 10px;
    margin-top: 7px;
    color: color('basic', 'red')
  }
  &-create-forgot {
    width: 100%;
    position: absolute;
    bottom: -30px;
    left: 0;
  }
  &-link {
    font-size: 11px;
  }
  &-forgot {
    color: color('brand', 'secondary');
    border-bottom: 1px dotted color('brand', 'secondary');
    font-style: italic;

    &:hover {
      text-decoration: none;
    }
  }
  &-create {
    color: color('brand', 'secondary');
    border-bottom: 1px dotted color('brand', 'secondary');
    font-style: italic;
    &:hover {
      text-decoration: none;
    }
  }
  &-powered-wrapper {
    position: absolute;
    bottom: 0;
    margin-bottom: 10px;
  }
  &-powered {
    margin-right: 10px;
    font-size: 10px;
    color: color('brand', 'secondary');
  }
  &-form-powered {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
  }
}
