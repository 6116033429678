.btn {
  position: relative;
  display: flex;
  align-content: center;
  justify-content: center;
  width: auto;
  min-width: 80px;
  border: 1px solid transparent;
  font-size: 13px;
  text-align: center;
  padding: 8px 15px;
  cursor: pointer;
  transition: $transition-fast;
  &:focus {
    outline: none;
  }
  &:disabled {
    cursor: not-allowed;
    background: color('gray', 'lighten-5');
    border: 1px solid color('gray', 'lighten-4');
    color: color('gray', 'lighten-1') !important;
    &:hover {
      cursor: not-allowed;
      background: color('gray', 'lighten-5');
      border: 1px solid color('gray', 'lighten-4');
      color: color('gray', 'lighten-1');
    }
  }
  &-primary {
    border: 1px solid color('brand', 'primary');
    background: color('brand', 'primary');
    color: color('basic', 'white');
    &:hover {
      background-color: darken(color('brand', 'primary'), 5)
    }
  }
  &-tabs {
    @extend .btn-primary;
    position: absolute;
    top: 5px;
    right: 5px;
    z-index: $zIndex-4;
  }
  &-secondary {
    border: 1px solid color('brand', 'secondary');
    background: color('brand', 'secondary');
    color: color('basic', 'white');
    &:hover {
      background-color: darken(color('brand', 'secondary'), 5)
    }
  }
  &-cancel {
    background-color: color('gray', 'lighten-4');
    color: color('gray', 'darken-3');
    border: 1px solid color('gray', 'lighten-1');
    &:hover {
      background-color: color('gray', 'lighten-2');
      border-color: color('gray', 'lighten-1');
    }
  }
  &-danger {
    background-color: color('basic', 'red');
    color: color('basic', 'white');
    border: 1px solid color('basic', 'red');
    &:hover {
      background-color: darken(color('basic', 'red'), 5);
    }
  }
  &-flatLight {
    background: transparent;
    color: color('basic', 'white');
    font-size: 15px;
  }
  &-flatDark {
    background: transparent;
    color: color('brand', 'secondary');
    font-size: 15px;
  }
  &-flatRed {
    background: transparent;
    color: color('basic', 'red');
    font-size: 15px;
  }
  &-selectAdd {
    width: 30px;
    min-width: 30px;
    height: 30px;
    border-radius: 50%;
    border: 1px solid color('brand', 'primary');
    background: color('brand', 'primary');
    color: color('basic', 'white');
    font-size: 12px;
    line-height: 12px;
    padding: 5px;
    transition: $transition-fast;
    outline: none;
    &:hover {
      background-color: darken(color('brand', 'primary'), 5);
    }
  }
  &-selectAdd-position {
    position: absolute;
    left: 240px;
    top: 21px;
    .btn-selectAdd {
      padding: 8px 0 0 1px;
    }
  }

  &-upload {
    width: 30px;
  }

  &-avatar {
    color: color('brand', 'secondary');
    position: absolute;
    bottom: 5px;
    right: 10px;
    background: color('basic', 'white');
    padding: 0;
    min-width: inherit;
    font-size: $fontSize-kilo;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    border: 1px solid color('brand', 'primary');
    transition: $transition-fast;
    display: flex;
    justify-content: center;
    align-items: center;

    &:hover {
      color: color('basic', 'white');
      background: color('brand', 'secondary');
      border-color: color('brand', 'secondary');
    }
  }

  &-icon {
    background: none;
    border: 0;
    min-width: inherit;

  }
}

.btn--small {
  position: relative;
  display: flex;
  align-content: center;
  justify-content: center;
  width: auto;
  min-width: 20px;
  border: 1px solid transparent;
  font-size: 12px;
  text-align: center;
  padding: 5px;
  margin: 10px 0;
  cursor: pointer;
  transition: $transition-fast;
}

.invoice {
  &-options {
    @include row-center--start;
  }
  &-generate {
    position: relative;
    margin-right: 5px;
    span {
      position: absolute;
      top: -22px;
      padding: 3px 5px;
      left: 50%;
      transform: translateX(-50%);
      background: color('gray', 'darken-4');
      display: none;
      color: color('basic', 'white');
      z-index: 9999;
      font-size: 12px;
    }
    &:hover {
      span {
        display: block;
      }
    }
  }
  &-upload {
    position: relative;
    span {
      position: absolute;
      top: -22px;
      padding: 3px 5px;
      left: 50%;
      transform: translateX(-50%);
      background: color('gray', 'darken-4');
      color: color('basic', 'white');
      display: none;
      z-index: 9999;
      font-size: 12px;
    }
    &:hover {
      span {
        display: block;
      }
    }
    .btn-flatDark {
      color: color('brand', 'primary');
    }
  }
  &-add {
    position: relative;
    span {
      position: absolute;
      top: -22px;
      padding: 3px 5px;
      left: 50%;
      transform: translateX(-50%);
      background: color('gray', 'darken-4');
      color: color('basic', 'white');
      display: none;
      z-index: 9999;
      font-size: 12px;
      white-space: nowrap;
    }
    &:hover {
      span {
        display: block;
      }
    }
    .btn-flatDark {
      color: color('brand', 'primary');
    }
  }
  &-remove {
    position: relative;
    span {
      position: absolute;
      top: -22px;
      padding: 3px 5px;
      left: 50%;
      transform: translateX(-50%);
      background: color('gray', 'darken-4');
      color: color('basic', 'white');
      display: none;
      z-index: 9999;
      font-size: 12px;
      white-space: nowrap;
    }
    i {
      color: color('basic', 'red');
    }
    &:hover {
      span {
        display: block;
      }
    }
    .btn-flatDark {
      color: color('basic', 'red');
    }
  }
}

.ytd-summary-component {
  margin-bottom: 20px;
}
