.truck-board-wrapper {

    .main-box-wrapper {

        background: transparent;
        border: none;
    }

    .main-box-content {
        overflow-x: visible;

        .btn-add {
            position: fixed;
            top: 120px;
            right: 80px;
            z-index: 5;
            padding: 11px;
        }
    }

    .m-truckRow__extended {
        width: 100%;
        opacity: 0;
        visibility: hidden;
        pointer-events: none;
        transform: translate3d(0, -10px, 0);
        transition: .2s;
        background-color: transparent;
        max-height: 0;
        display: flex;
        justify-content: space-between;
        align-items: flex-start;

        &.shown {
            visibility: visible;
            max-height: 500px;
            pointer-events: all;
            opacity: 1;
            transform: translate3d(0, 0, 0);
            margin-top: 30px;

            textarea {
                border: none;
                max-height: 120px;
                resize: none;
            }

            .icon-ok-circled {

                &::before {
                    color: color('brand', 'primary');
                }
            }
        }
    }

}

.truck-board-status-field {
    color: color('basic', 'white');
    border-radius: 3px;
    display: flex;
    align-items: center;
    justify-content: center;

    &.green {

        background-color: color('basic', 'green-status');
    }

    &.red {
        background-color: color('basic', 'red-status');
    }
}

.truck-board-clear-dispatchers {
    position: absolute;
    right: 1px;
    top: 3px;
    font-size: 12px;
    cursor: pointer;
}

.last-stop {
    display: flex;
    flex-wrap: wrap;

    & > * {
        width: 100%;
        display: block;
    }
}


//loading

@keyframes fontblurer {
    0% {
        text-shadow: 0 0 15px black;
    }
    30% {
        text-shadow: 0 0 10px black;
    }
    100% {
        text-shadow: 0 0 5px black;
    }
}

.truck-board-extended-row .loader {
    .extend-title {
        text-shadow: 0 0 15px black;
        color: transparent;
        animation: fontblurer 1.2s ease;
    }
}

.m-table__content.-location.loader {
    .m-table__content--wrapper {
        div {
            animation: fontblurer 1.5s ease;
            color: transparent;
        }
    }
}

.truck-board-hos.m-table__content.loader {
    .truck-board-hos-row {
        div {
            animation: fontblurer 1.5s ease;
            color: transparent;
        }
    }
}

.m-table__content.-lastStop.loader {
    .last-stop span{
        animation: fontblurer 1.5s ease;
        color: transparent;
    }
}

.m-table__content.-booked.loader {
    span {
        animation: fontblurer 1.5s ease;
        color: transparent;
    }
}

.m-table__content.-note.loader{
    em.icon-edit {
        animation: fontblurer 1.5s ease;
        color: transparent;
    }

    .validation-field-input {
        box-shadow: none;
    }
}


