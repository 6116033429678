.toggle-switcher {
  width: 80px;
  height: 26px;
  background: color('gray', 'darken-1');
  position: relative;
  border-radius: 50px;
  &:after {
    content: 'Off';
    color: color('basic', 'white');
    position: absolute;
    right: 10px;
    z-index: 0;
    font-size: 12px;
    line-height: 25px;
    letter-spacing: 1px;
  }
  &:before {
    content: 'On';
    color: color('basic', 'white');
    position: absolute;
    left: 10px;
    z-index: 0;
    font-size: 12px;
    line-height: 25px;
    letter-spacing: 1px;
  }
  label {
    display: block;
    width: 34px;
    height: 20px;
    cursor: pointer;
    position: absolute;
    top: 3px;
    left: 3px;
    z-index: 1;
    background: #fcfff4;
    border-radius: 50px;
    transition: all 0.4s ease;
  }
  input[type=checkbox] {
    visibility: hidden;
    &:checked + label {
      left: 43px;
    }
  }
  &-checked {
    background: color('brand', 'primary');
  }
}