.crud-table {
  &-wrapper {
    width: 100%;
    display: flex;
    position: relative;

    .models-table-wrapper {
      width: 100%;
    }

    .btn-crud-table {
      position: absolute;
      right: 0;
      top: -10px;
    }
  }

  &-validation-field {
    position: relative;
    display: flex;
    margin-bottom: 5px;
  }

  &-validation-required {
    position: absolute;
    bottom: 5px;
    left: 0;
    color: color('basic', 'red');
    font-size: 12px;
  }

}