.display-field {

  margin-bottom: 20px;
  &-label {
    font-size: 12px;
    color: color('gray', 'darken-3');
    margin-bottom: 5px;
  }

  &-value {
    border-bottom: 1px solid color('gray', 'lighten-2');
    padding: 10px 10px 10px 0;
    font-size: 12px;
    line-height: 12px;
    color: $color-input-text;
    min-height: 33px;
  }
}