.side-bar {
  &-component {
    position: fixed;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    text-align: center;
    top: 0;
    left: 0;
      z-index: 6;
    padding-bottom: 60px;
    width: 200px;
    height: 100vh;
    color: $side-bar-color;
    background: $side-bar-bg;
    .user-avatar-component {
      min-width: 100px;
      min-height: 100px;
      width: 100px;
      height: 100px;
      margin: 20px 0 0 0;
      border: none;
    }
  }
  &-current-user {
    width: 100%;
    padding: 10px;
  }
  &-menu {
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    background: transparent;
    color: $side-bar-color;
    overflow-y: auto;
  }
  &-menuItem {
    position: relative;
    width: 100%;
    align-items: center;

        .active {
            color: color('basic', 'white');
            text-decoration: none;
            background: darken($side-bar-bg, 7);
        }
        a {
            width: 100%;
            padding: 15px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            color: $side-bar-color;
            font-size: $fontSize-normal;
            align-content: center;
            &:hover {
                color: color('basic', 'white');
                text-decoration: none;
                background: darken($side-bar-bg, 7);
            }
        }

    }

    &-menuItem-icon {
        font-size: 28px;
        margin-bottom: 5px;
    }

    &-menuTitle {
        width: 100%;
        padding: 15px;
        color: color('basic', 'white');
        border-bottom: 1px solid color('basic', 'white');
        display: flex;
    }
    &-logout-label {
        opacity: 0;
        margin-left: -50px;
        transition: $transition-fast;
    }
    &-logout {
        background: color('brand', 'primary');
        width: 100%;
        padding: 0;
        position: absolute;
        bottom: 0;
        left: 0;
        text-align: center;
        .btn-flatLight {
            position: relative;
            width: 100%;
            &:after {
                content: '\e812';
                font-family: 'fontello';
                position: absolute;
                top: 5px;
                left: 50%;
                transform: translateX(-50%);
                line-height: 22px;
                font-size: 22px;
                color: color('basic', 'white');
                transition: $transition-fast;
            }
        }
        &:hover .side-bar-logout-label {
            opacity: 1;
            margin-left: 0;
        }
        &:hover .btn-flatLight:after {
            left: 70%;
            opacity: 0;
        }
    }
    &-current-user a {
        color: color('basic', 'white');
        text-decoration: none;
    }
    &-powered {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        position: absolute;
        width: 100%;
        background: color('brand', 'secondary');
        padding: 0 5px 3px 5px;
        bottom: 35px;
        border-top: 1px solid color('gray', 'darken-2');
        .powered {
            color: $side-bar-color;
            font-size: 9px;
        }
        .powered-img {
            width: 100%;
            max-width: 55px;
            background: color('brand', 'secondary');
            padding: 2px 5px;
        }
    }
}

.side-bar-component {

    .user-avatar-component {
        min-width: 100px;
        min-height: 100px;
        width: 100px;
        height: 100px;
        margin: 20px 0 0 0;
    }
}

.js-user-avatarScroll {
    .user-avatar-component {
        min-width: 50px;
        min-height: 50px;
        width: 50px;
        height: 50px;
        margin: 20px 0 0 0;
        //min-width: 35px;
        //min-height: 35px;
        //width: 35px;
        //height: 35px;
        //margin: -20px 135px 0 0;
    }
}
