.checkStatus {
  &-wrapper {
    height: auto;
    min-height: 100vh;
    background: #f1f4f9;
    padding: 20px;
    display: flex;
    flex-direction: column;
  }
  &-row {
    display: flex;
    justify-content: space-between;
    @media (max-width: 768px) {
      flex-direction: column;
    }
  }
  &-field-row {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
    .display-field {
      width: 48%;
    }
  }
  &-subtitle {
    font-size: 12px;
    line-height: 16px;
    width: 200px;
    text-align: left;
    margin: 15px 0 0 0;
  }
  &-company {
    width: 100%;
    display: flex;
    justify-content: space-between;
    background: color('basic', 'white');
    padding: 30px;
    box-sizing: border-box;
    margin-bottom: 20px;

    h2 {
      font-size: 20px;
      margin-bottom: 10px;
    }
    p {
      font-size: 14px;
      margin: 0;
      line-height: 18px;
    }
  }
  &-box {
    position: relative;
    display: flex;
    height: auto;
    overflow-x: hidden;
    width: 100%;
    box-sizing: border-box;
    flex-direction: column;
    padding: 20px;
    background: color('basic', 'white');
    border: 1px solid #eaebed;
    margin-bottom: 20px;
  }
  &-title {
    font-size: 20px;
    font-weight: bold;
    color: color('brand', 'secondary');
  }
  &-content {
    display: flex;
    flex-direction: column;
  }
  &-company-details{

  }
  &-logo{
    img {
      width: auto;
      height: 65px;
    }
  }
  &-general {
    width: 40%;

    @media (max-width: 768px) {
      width: 100%;
    }
  }
  &-stops{
    width: 59%;
    @media (max-width: 768px) {
      width: 100%;
    }
  }
  &-events {
    position: relative;

  }
  &-status {
    position: absolute;
    right: 20px;
    top: 25px;
    font-size: 14px;
  }
  &-documents {
    width: 49.5%;
    @media (max-width: 768px) {
      width: 100%;
    }
  }
  &-location {
    width: 49.5%;
    @media (max-width: 768px) {
      width: 100%;
    }

    .location-details {
      font-size: 12px;
      margin-bottom: 10px;
    }

    .disclaimer {
      font-size: 10px;
      line-height: 14px;
      margin-top: 10px;
    }

    .location-time {
      margin-left: 20px;
    }
  }
  &-footer {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;

    .btn {
      position: absolute;
      right: 0;
      bottom: 25px;
      &:hover {
        text-decoration: none;
      }

      @media (max-width: 768px) {
        position: relative;
        bottom: inherit;
        right: inherit;
        margin-left: 20px;
      }
    }
  }
}