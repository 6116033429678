.ember-power-select {
  &-trigger {
    min-width: 200px;
    //max-width: 220px;
    border-radius: 0;
    height: 40px;
    border: 1px solid #dce1e4;
    box-shadow: $shadow-light;
    color: color('brand', 'secondary');
    padding: 6px 2px;
    overflow: hidden;
    font-size: 13px;
    &:focus {
      outline: none;
    }
  }
  &-status-icon {
    border-color: transparent;
    &:after {
      content: '\f004';
      font-family: "fontello";
      position: absolute;
      top: -12px;
      right: 10px;
      color: color('brand', 'primary');
      font-size: 12px;
      transition: $transition-fast;
    }
  }
  &-search-input {
    border: 1px solid color('gray', 'lighten-2') !important;
    &:focus {
      outline: none;
    }
  }
}

.ember-power-select-dropdown {
  border: 1px solid color('gray', 'lighten-2') !important;
  border-top: none !important;
}

.ember-power-select-selected-item {
  font-size: 12px;
  color: $color-input-text;
}

.ember-power-select-selected-item {
  font-size: 12px;
  color: $color-input-text;
}

.ember-power-select-option {
  font-size: 12px;
  color: $color-input-text;
  padding: 5px 10px;
}

.ember-power-select-option[aria-current="true"] {
  background: color('gray', 'lighten-2');
  color: $color-input-text;
}

.ember-power-select-option[aria-selected="true"] {
  background: color('brand', 'secondary');
  color: color('basic','white');
}

.ember-power-select-option--no-matches-message {
  font-size: 12px;
  color: $color-input-text;
}

.test {
  .ember-power-select-trigger {
    height: auto;
  }
}
.ember-power-select-multiple-option {
  border: 1px solid color('gray', 'lighten-2');
  border-radius: 2px;
  color: color('gray', 'darken-1');
  background-color: color('basic', 'white');
  padding: 2px 10px 2px 7px;
  display: inline-block;
  line-height: 1.45;
  position: relative;
  float: left;
  margin: 2px;
  font-size: 11px;
  box-shadow: 1px 1px 2px color('gray', 'lighten-2');
  transition: $transition-medium;
  &:hover {
    background: color('gray', 'lighten-3');
    cursor: pointer;
  }
}

.large-select {
  .ember-power-select-trigger {
    height: 60px;
    padding-left: 10px;
  }

  .ember-power-select-selected-item {
    margin-left: 0;
  }
}
