.file-upload {

  &-wrapper {
    position: relative;
    margin: 10px 0 20px 0;
  }
  &-button {
    border: 1px solid black;
    padding: 10px;
    cursor: pointer;
  }

  &-button-wrapper {
    position: absolute;
    top: 25px;
    left: 0;
    width: 100%;

    .file-upload {
      text-align: center;
      position: relative;
      float: left;
      width: 100%;
    }
  }

  &-btn--add {
    border: 1px solid gray;
    cursor: pointer;
    margin: 0 auto;
  }

  &-wrapper {
  }

  &-dropZone-wrapper {
    max-width: 300px;
    position: relative;
    margin: 0 auto;
  }

  &-dropZone {
    min-width: 245px;
    height: auto;
    border: 1px dashed color('brand', 'secondary');
    padding: 100px 40px 10px 40px;
    text-align: center;
  }

  &-confirm {
    position: absolute;
    bottom: -15px;
    left: 0;
    width: 100%;
    text-align: center;
    font-size: $fontSize-normal;
    line-height: 18px;
  }

  &-error {
    position: absolute;
    bottom: 5px;
    right: 0;
    width: 100%;
    text-align: center;
    font-size: $fontSize-normal;
    color: red;
  }

  &-tmp-image {

  }
}

