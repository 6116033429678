/* Customize the label (the container) */
.checkbox-container {
  display: block;
  position: relative;
  padding-left: 25px;
  font-size: 12px;
  line-height: 20px;
  color: color('gray', 'darken-3');
  cursor: pointer;
  user-select: none;
  margin-bottom: 20px;
  transition: $transition-fast;

  /* Hide the browser's default checkbox */
  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    transition: $transition-fast;
  }

  /* Create a custom checkbox */
  .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 18px;
    width: 18px;
    background-color: color('basic', 'white');
    border: 1px solid color('brand', 'primary');
    box-sizing: border-box;
    transition: $transition-fast;
  }

  /* On mouse-over, add a grey background color */
  &:hover input ~ .checkmark {
    background-color: color('gray', 'lighten-4');
  }

  /* When the checkbox is checked, add a blue background */
  input:checked ~ .checkmark {
    background-color: color('basic', 'white');
  }

  /* Create the checkmark/indicator (hidden when not checked) */
  .checkmark:after {
    content: "";
    position: absolute;
    display: none;
  }

  /* Show the checkmark when checked */
  input:checked ~ .checkmark:after {
    display: block;
  }

  /* Style the checkmark/indicator */
  .checkmark:after {
    left: 6px;
    top: 2px;
    width: 5px;
    height: 10px;
    border: solid color('brand', 'primary');
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
  }
}

/* checbox style with other input elements */
.checkbox {
  &-wrapper {
    .validation-field-wrapper {
      margin-bottom: 0;
      height: 78px;
      @include row-center--start;
    }
    .checkbox-container {
      margin-bottom: 0;
    }
  }
}
