.company-logo {
  &-component {
    position: relative;
    width: 100%;
    display: block;
    text-align: center;
    padding: 25px 10px;
    justify-content: center;
    align-items: center;
    height: 70px;
    color: color('basic', 'white');
    font-size: $fontSize-kilo;
    font-weight: bold;
    background: color('brand', 'primary');

    a {
      text-decoration: none;
      max-width: 200px;
      color: color('basic', 'white');

      &:hover {
        text-decoration: none;
      }
    }
  }
}

.company-avatar {
  margin-bottom: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
}
