.filter-bar {
  background: white;
  padding: 10px;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-bottom: 20px;


  & > div:first-of-type {
    width: 100%;
    font-size: $fontSize-normal;
    padding: 0 10px;
    margin-bottom: 10px;
    font-weight: 600;
    color: color('gray', 'darken-5');
  }


  input {
    padding-top: 15px;
  }

  .validation-field-input-wrapperCalendar {
    margin: 0 10px;
  }

}

.top-period {
  font-size: $fontSize-mili;
  color: color('brand', 'secondary');
  opacity: .5;
  position: absolute;
  z-index: 2;
  top: 2px;
  left: 10px;
}