.homepage-box {
  &-component {
    background: $box-bg;
    margin-bottom: 20px;
    box-shadow: 2px 3px 34px -7px color('brand', 'secondary');
    height: calc(50vh - 40px);
    display: flex;
    flex-direction: column;
    width: 100%;
    box-sizing: border-box;
    overflow: hidden;
    a {
      &:hover {
        text-decoration: none;
      }
    }
  }
  &-wrapper {
    position: relative;
    height: calc(50vh - 40px);
    display: flex;
    overflow: hidden;
  }
  &-link {
    position: absolute;
    bottom: 0;
    right: 25px;
    text-align: right;
    color: color('basic', 'white');
    width: 100%;
    font-size: $fontSize-tera + 4px;
    z-index: 200;
  }
  &-description {
    position: relative;
    color: color('basic', 'white');
    width: 200px;
    font-size: $fontSize-kilo;
    z-index: 200;
    opacity: 0;
    transition: $transition-medium;
    text-align: right;
    border-right: 1px solid color('basic', 'white');
    margin: 35px 0 0 -10px;
    padding: 0 10px 0 0;
    text-transform: uppercase;
  }
  &-img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    transform: scale(1.25);
    transition: $transition-medium;
    z-index: 100;
    &:after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: rgba(color('brand', 'primary'), .1);
      transition: $transition-medium;
    }
  }
  &-safety {
    background-image: url(/img/safety.jpg);
  }
  &-tracking {
    background-image: url(/img/tracking.jpg);
  }
  &-dispatch {
    background-image: url(/img/dispatch.jpg);
  }
  &-accounting {
    background-image: url(/img/accounting.jpg);
  }
}
.homepage-box-wrapper {
  &:hover .homepage-box-img{
    margin-left: 2%;
    transform: scale(1.1);
  }
  &:hover .homepage-box-img:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(color('brand', 'secondary'), .7);
  }
  &:hover .homepage-box-description {
    opacity: 1;
    margin: 35px 0 0 20px;
  }
}

.homepage-box-disabled {
  .homepage-box-wrapper {
    pointer-events: none;
    &:after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: rgba(color('brand', 'secondary'), .7);
      z-index: $zIndex-3;
    }
  }
}