.payment {
  &-wrapper {
    display: flex;
    flex-direction: column;
    a {
      color: color('gray', 'darken-4');
      &:hover {
        text-decoration: none;
      }
    }
  }
  &-link {
    @include column-center--spaceBetween;
    i {
      font-size: 100px;
      color: color('brand', 'primary');
      transition: $transition-fast;
    }
  }
  &-link-wrapper {
    background: color('basic', 'white');
    width: 100%;
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: calc(50vh - 75px);
    transition: $transition-fast;
    &:hover {
      box-shadow: $shadow-basic;
      i {
        color: darken(color('brand', 'primary'), 10%);
      }
    }
  }

  &-label {
    font-size: 20px;
  }
}
