.models-table {
  &-wrapper {
    width: 100%;

    .table-header {
      cursor: pointer;
    }

    .globalSearch input {
      padding: 5px;
    }
    .btn {
      position: inherit;
    }

    td {
      .checkbox-container {
        height: 10px;
      }

      .checkmark {
        top: 6px;
        left: 50%;
        margin-left: -9px;
      }
    }
  }
}

.glyphicon-triangle-bottom {
  position: relative;
  &:after {
    content: '';
    position: absolute;
    top: 7px;
    left: 7px;
    width: 8px;
    height: 8px;
    background: color('basic', 'white');
    transform: rotate(45deg);
    border-top: 2px solid color('brand', 'primary');
    border-left: 2px solid color('brand', 'primary')
  }
}
.glyphicon-triangle-top {
  position: relative;
  &:after {
    content: '';
    position: absolute;
    top: 4px;
    left: 7px;
    width: 8px;
    height: 8px;
    background: color('basic', 'white');
    transform: rotate(-135deg);
    border-top: 2px solid color('brand', 'primary');
    border-left: 2px solid color('brand', 'primary')
  }
}

.table-footer {

  .table-nav {

    .btn-group {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      align-items: flex-start;
      position: relative;
      margin-top: -15px;
      margin-right: 15px;
      a {
        width: 10px;
        min-width: 10px;
      }
      .glyphicon-chevron-left {
        position: relative;
        &:after {
          content: '<<';
          position: absolute;
          font-style: normal;
          top: 4px;
          left: 7px;
          width: 8px;
          height: 8px;
          font-size: 20px;
          color: color('brand', 'secondary');
        }
      }

      .glyphicon-menu-left {
        position: relative;
        &:after {
          content: '<';
          position: absolute;
          font-style: normal;
          top: 4px;
          left: 12px;
          width: 8px;
          height: 8px;
          font-size: 20px;
          color: color('brand', 'secondary');
        }
      }

      .glyphicon-menu-right {
        position: relative;
        &:after {
          content: '>';
          position: absolute;
          font-style: normal;
          top: 4px;
          left: 4px;
          width: 8px;
          height: 8px;
          font-size: 20px;
          color: color('brand', 'secondary');
        }
      }


      .glyphicon-chevron-right {
        position: relative;
        &:after {
          content: '>>';
          position: absolute;
          font-style: normal;
          top: 4px;
          left: 4px;
          width: 8px;
          height: 8px;
          font-size: 20px;
          color: color('brand', 'secondary');
        }
      }
    }

  }

}

