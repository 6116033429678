.tabs {
  &-nav {

    display: flex;
    border-bottom: 1px solid color('gray', 'lighten-1');

    .active {
      border: 1px solid color('gray', 'lighten-1');
      border-bottom-color: transparent;
      margin-bottom: -1px;
    }
  }

  &-nav-item {
    border: 1px solid color('basic', 'white');
    background-color: color('basic', 'white');
    padding: 10px;
    box-sizing: border-box;
    color: color('gray', 'darken-1');

    &:hover {
      text-decoration: none;
    }
  }


  &-content {
    display: flex;
    flex-direction: column;
    width: 100%;
    background-color: color('basic', 'white');
    padding: 30px 10px 20px 10px;
    box-sizing: border-box;
    border: 1px solid color('gray', 'lighten-1');
    border-top: none;
    position: relative;
  }
}
