.regular-modal {
  &-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    overflow: hidden;
    background: rgba(color('gray', 'darken-4'), 0.4);
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    z-index: $zIndex-7;
    .regular-modal-overlay {
      height: auto;
    }
  }
  &--small {
    max-width: 520px;
  }
  &--extraSmall {
    max-width: 320px;
  }
  &--large {
    max-width: 600px;
  }
  &--extraLarge {
    max-width: 800px;
  }
  &--statement {
    max-width: 1000px;
  }
  &--stop {
    max-width: 520px;
    .regular-modal-content {
      height: 350px;
      .form-content {
        max-height: 403px;
      }
    }
  }

  &-wrapper {
    min-width: 300px;
    margin-top: 5%;
    width: 100%;
    background: color('basic', 'white');
    height: auto;
    z-index: $zIndex-8;
    animation: showModal .3s ease;
    animation-fill-mode: forwards;
    transform: scaleY(0);
    .regular-modal-wrapper {
      margin-top: 0;
    }
  }
  &-header {
    background: color('gray', 'lighten-5');
    border: 1px solid color('gray', 'lighten-2');
    padding: 17px 20px;
    min-height: 40px;
    color: color('brand', 'secondary');
    font-size: $fontSize-normal;
    text-transform: uppercase;
    font-weight: 500;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    .btn {
      min-width: inherit;
      padding: 0;
      font-size: 17px;
    }
  }
  &-content {
    position: relative;
    font-size: $fontSize-normal;
    display: flex;
    flex-direction: column;

    .form {

      width: 100%;
      position: relative;

      &-content {
        width: 100%;
        height: auto;
        max-height: 461px;
        padding: 20px 20px 80px 20px;
        overflow-y: auto;
      }

      &-footer {
        width: 100%;
        position: absolute;
        bottom: 0;
        left: 0;
        display: flex;
        flex-direction: row-reverse;
        padding: 10px 20px;
        border-top: 1px solid color('gray', 'lighten-1');
        background: color('basic', 'white');

        .btn {
          margin-left: 15px;
        }
      }
    }
  }
  &-footer {
    position: relative;
    top: 298px;
    left: 0;
    padding: 10px;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    width: 100%;
    background: color('basic', 'white');
  }
}

@keyframes showModal {
  from {
    transform: scaleY(0);
  }
  to {
    transform: scaleY(1);
  }
}

.regular-modal--stop {

  .form-content {
    max-height: 560px;
  }

}
