.accounting {
  &-chart-row {
    margin-bottom: 50px;

    .total-gross {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 10px;
      font-size: 16px;
    }
  }

  &-general-wrapper {
    display: flex;
    flex-direction: column;

  }
  &-link {
    border: 1px solid #eaebed;
    width: 100%;
    padding: 50px;
    margin-bottom: 20px;
    text-align: center;
    transition: $transition-fast;
    color: color('brand', 'primary');
    font-weight: bold;
    font-size: 20px;

    &:hover {
      box-shadow: 0 3px 10px #9e9e9e;
      text-decoration: none;
      color: color('brand', 'secondary');
    }
  }

  &-year-filter {
    margin-left: 10px;
  }

}

.fuel-table {
  margin-bottom: 20px;

  th {
    background: #eaebed;
  }
  td, th {
    border: 1px solid black;
    padding: 5px;
  }
}