.signUp {
  &-header {
    @include row-center--spaceEvenly;
    font-size: $fontSize-mega;
    padding: 15px 0;
    text-transform: uppercase;
    color: color('brand', 'secondary');
    border-bottom: 1px solid color('gray', 'lighten-3');
    box-shadow: 1px 2px 8px color('gray', 'lighten-3');
    &-title {
      border-left: 1px solid color('gray', 'lighten-1');
      padding-left: 10px;
    }
    p {
      font-size: $fontSize-micro;
      margin: 3px 0;
      text-transform: none;
    }
  }
  &-content {
    position: relative;
    width: 100%;
    height: calc(70vh - 70px);
    padding: 10px 20px;
    overflow-y: auto;
    overflow-x: hidden;
    text-align: center;
  }
  &-page {
    position: relative;
    background: url(/img/login-img3.jpg) center center no-repeat;
    width: 100%;
    background-size: cover;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 30px;
    &:after {
      content: '';
      position: absolute;
      background: rgba(color('gray', 'base'), 0.4);
      top: 0;
      right: 0;
      width: 100%;
      height: 100%;
    }
    form {
      position: relative;
      width: 100%;
      z-index: 9999;
      justify-content: space-around;
      align-items: center;
      display: flex;
      flex-direction: column;
    }
  }
  &-icon {
    position: absolute;
    top: 0;
    left: 0;
    background: whitesmoke;
    color: #737174;
    font-size: 20px;
    width: 65px;
    padding: 5px 5px 5px 12px;
    box-sizing: border-box;
    border-bottom-right-radius: 70px;
    span {
      position: absolute;
      font-size: 12px;
      left: 75px;
      top: 5px;
      width: 150px;
    }
  }
  &-head-title {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    font-size: $fontSize-tera + 3px;
    color: color('basic', 'white');
    z-index: 900;
    width: 100%;
  }
  &-title {
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-bottom: 25px;
    font-size: $fontSize-mega;
    color: color('brand', 'secondary');
    z-index: 900;
    width: 100%;
    padding-bottom: 10px;
    &:after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 50%;
      transform: translateX(-50%);
      width: 100px;
      height: 1px;
      background: color('gray', 'base');
    }
  }
  &-formBox {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 600px;
    height: 80vh;
    background: color('basic', 'white');
    text-align: left;
    padding: 0 0 20px 0;
    transition: $transition-fast;
    box-sizing: border-box;
    overflow: hidden;
    &:hover {
      box-shadow: $shadow-light;
    }
    .validation-field-input {
      min-width: 120px;
      max-width: 180px;
    }
  }
  &-footer {
    position: absolute;
    width: 100%;
    height: 100%;
    right: 0;
    top: 0;
    .btn {
      position: absolute;
      right: 10px;
      bottom: 10px;
    }
  }
  &-link {
    text-align: right;
    font-size: 12px;
    margin-right: 15px;
    a {
      color: color('brand', 'secondary');
      &:hover {
        text-decoration: none;
      }
    }
  }
}

.forgot {
  &-wrapper {
    position: relative;
    width: 100%;
    padding: 50px 100px;
    overflow-y: auto;
    overflow-x: hidden;
    text-align: center;

    a {
      font-size: 11px;
      color: color('brand', 'secondary');
      border-bottom: 1px dotted color('brand', 'secondary');
      font-style: italic;

      &:hover {
        text-decoration: none;
      }
    }

  }
  &-footer {
    margin: 0 0 20px 0;
    display: flex;
    align-items: center;
    justify-content: center;

    button {
      margin: 0;
    }
  }
}
