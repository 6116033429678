.form {

  &-content {

  }

  &-footer {
    display: flex;
    flex-direction: row-reverse;
    align-items: flex-end;
    .btn {
      margin-left: 20px;
    }
  }

  &-subtitle {
    color: color('brand', 'secondary');
  }
}

.changePass {
  width: 100%;
  font-size: 12px;
  margin-bottom: 20px;
}