.rate-toggler {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 10px;
  width: 100%;

  &-label {
    width: 150px;
    display: flex;
    flex-wrap: wrap;
  }

  .toggle-switcher {
    margin-right: 20px;
  }

  input {
    margin-right: 20px;
  }
}