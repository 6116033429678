.star-rating {
  display: flex;
  align-items: center;

  &-label {
    margin-right: 10px;
  }
}

.rating-circle {
  position: relative;
 display: flex;
  border-radius: 50%;
  background: color('gray', 'lighten-2');
  width: 20px;
  height: 20px;
  overflow: hidden;
  box-sizing: border-box;
  margin-right: 5px;

  &--full {
    background: #FFEF26;
  }
}

.segment {
  width: 10%;
  height: 100%;
  background: #FFEF26;
}